<template>
  <v-app class="background">
    <Toolbar />
    <v-container>
      <v-layout row wrap justify-center align-content-center pt-16 class="bottom">
        <v-flex xs10 lg4>
          <v-card max-width="350">
            <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
              <v-img src="@/assets/img/SantolSorbetto.jpg"></v-img>
            </v-card-text>
          </v-card>
          <v-card
            style="position: absolute; top: 350px; left: 355px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="350"
          >
            <v-card-title class="fontHeader" data-aos="fade-up" data-aos-easing="ease-in-sine">
              Santol Sorbetto
            </v-card-title>

            <v-card-subtitle
              class="brown--text text-left pt-4"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              กระท้อน ผลไม้ไทยที่ทำได้ทั้งอาหารคาวและขนมหวาน
              คราวนี้เราเลยนำกระท้อนที่ได้มาจากสวนคุณลุง จ.สุราษฎร์ธานี มาทำเจลาโต้รสกระท้อน
              เป็นเจลาโต้แบบซอร์เบท(sorbeto)ที่ใช้เนื้อกระท้อนเฉพาะที่เป็นปุยๆเท่านั้นทำให้รสชาติที่ได้มีความหวานฉ่ำอม
              เปรี้ยวนิดๆ และมีเนื้อกระท้อนนุ่มๆปนอยู่ด้วย ซึ่งโดยปกติของการทำเจลาโต้ซอร์เบท
              จะต้องมีน้ำเป็นส่วนประกอบเราจึงนำเนื้อกระท้อนมาคั้นน้ำเพื่อให้ได้รสกระท้อนธรรมชาติ
              ปราศจากกลิ่น และรสปรุงแต่ง
            </v-card-subtitle>
          </v-card>
        </v-flex>
        <v-flex xs10 lg4>
          <v-card max-width="350">
            <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
              <v-img src="@/assets/img/Durian.jpg"></v-img>
            </v-card-text>
          </v-card>
          <v-card
            style="position: absolute; top: 350px; left: 750px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="350"
          >
            <v-card-title class="fontHeader" data-aos="fade-up" data-aos-easing="ease-in-sine">
              Durian Cheese Pie Gelato
            </v-card-title>

            <v-card-subtitle
              class="brown--text text-left pt-4"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              ทุเรียนกระดุมจากจังหวัดสุราษฎร์ธานี อำเภอบ้านนาสาร จุดเด่นคือรสหวานและเนื้อหอมอ่อนๆ
              เราเลือกนำมาทำเจลาโต้ โดยอาศัยความหวานของทุเรียนล้วนๆเป็นตัวชูรส ทานคู่กับครีมชีส
              พร้อมคัมเบิ้ล ที่เราออกแบบให้ เหมือนเป็นทาร์ตคล้ายกับชีสพาย จึงเป็นที่มาของชื่อเจลาโต้
              “รสทุเรียนชีสพาย”(Durian Cheese Pie Gelato)
            </v-card-subtitle>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <v-layout row wrap pb-2>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path
          fill="#9C7656"
          fill-opacity="1"
          d="M0,224L48,218.7C96,213,192,203,288,208C384,213,480,235,576,218.7C672,203,768,149,864,138.7C960,128,1056,160,1152,176C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </v-layout> -->

    <Footer />
  </v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Toolbar,
    Footer
  },
  data: () => ({
    show: false,
    showMatcha: false
  })
}
</script>
<style scoped>
.fontHeader {
  font-size: 20px;
  color: #9c7656;
}
.bottom {
  padding-bottom: 380px;
}
.background {
  background-color: #EDEDE7;
}
</style>
